@charset "UTF-8";
/* Body */
body {
	font-family: source-sans-pro;
	background-color: #f2f2f2;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	font-style: normal;
	font-weight: 200;
}
/* Container */
.container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	height: 1000px;
	;
	background-color: #FFFFFF;
	margin-top: 0px;
}
.product_section {
	background-color: #FFFFFF;
}
.product_section h2 {
	color: #6E6E6E;
}
.external_links  {
	color: #0B2161;
	text-decoration: none;
	font-size: 12px;
}
/* Navigation */
header {
	height: 5%;
	background-color: #134D75;
	border-bottom: 1px solid #2C9AB7;
}
.logo {
	color: #fff;
	font-weight: bold;
	text-align: undefined;
	width: 10%;
	float: left;
	margin-top: 15px;
	margin-left: 25px;
	letter-spacing: 4px;
}
.logoimg {
	left: 0;
	float:left;
}
nav {
	float: right;
	width: 50%;
	text-align: right;
	margin-right: 25px;
}
header nav ul {
	list-style: none;
	float: right;
}
nav ul li {
	float: left;
	color: #FFFFFF;
	font-size: 14px;
	text-align: left;
	margin-right: 25px;
	letter-spacing: 2px;
	font-weight: bold;
	transition: all 0.3s linear;
}
ul li a {
	color: #FFFFFF;
	text-decoration: none;
}
ul li:hover a {
	color: #2C9AB7;
}
.hero_header {
	color: #FFFFFF;
	text-align: center;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	letter-spacing: 4px;
}
/* Hero Section */
.hero {
	background-color: #FFFFFF;
}
.light {
	font-weight: bold;
	color: #717070;
}
.tagline {
	text-align: center;
	color: #FFFFFF;
	margin-top: 4px;
	font-weight: lighter;
	text-transform: uppercase;
	letter-spacing: 1px;
}
/* About Section */
.text_column {
	width: 29%;
	text-align: justify;
	font-weight: lighter;
	line-height: 25px;
	float: left;
	padding-left: 20px;
	padding-right: 20px;
	color: #A3A3A3;
}
.about {
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 35px;
	display: inline-block;
	background-color: #FFFFFF;
	margin-top: 0px;
}
/* Stats Gallery */
.stats {
	color: #717070;
	margin-bottom: 5px;
}
.gallery {
	clear: both;
	display: inline-block;
	width: 100%;
	background-color: #FFFFFF;
	/* [disabled]min-width: 400px;
*/
	padding-bottom: 35px;
	padding-top: 0px;
	margin-top: -5px;
	margin-bottom: 0px;
}
.thumbnail {
	width: 25%;
	text-align: center;
	float: left;
	margin-top: 35px;
}
.gallery .thumbnail h4 {
	margin-top: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
	margin-left: 5px;
	color: #52BAD5;
}
.gallery .thumbnail p {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	color: #A3A3A3;
}
/* Parallax Section */
.banner {
	background-color: #2D9AB7;
	background-image: url(images/parallax.png);
	height: 200px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
}
.parallax {
	color: #FFFFFF;
	text-align: right;
	padding-right: 110px;
	padding-top: 90px;
	letter-spacing: 2px;
	margin-top: 0px;
}
.parallax_description {
	color: #FFFFFF;
	text-align: right;
	padding-right: 110px;
	width: 320px;
	float: right;
	font-weight: lighter;
	line-height: 23px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-left: 10px;
	padding-top: 0px;
}
/* More info */
footer {
	background-color: #FFFFFF;
	padding-bottom: 2px;
	width: 100%;
	margin-top: 20px;
	margin-right: 0;
	margin-left: 0;
	margin-bottom: 0;
	padding-top: px;
}
.footer_column {
	width: 100%;
	padding-top: 1px;
	float: left;
	display: block;
}
.cards {
	width: 100%;
	height: auto;
	max-width: 400px;
	max-height: 200px;
}
.button {
	width: 200px;
	margin-top: 40px;
	margin-right: auto;
	margin-bottom: auto;
	margin-left: auto;
	padding-top: 20px;
	padding-right: 10px;
	padding-bottom: 20px;
	padding-left: 10px;
	text-align: center;
	vertical-align: middle;
	border-radius: 0px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 2px;
	border: 3px solid #FFFFFF;
	color: #FFFFFF;
	transition: all 0.3s linear;
}
.button:hover {
	background-color: #FEFEFE;
	color: #C4C4C4;
	cursor: pointer;
}
.copyright {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #717070;
	color: #FFFFFF;
	text-transform: uppercase;
	font-weight: lighter;
	letter-spacing: 2px;
	border-top-width: 2px;
}
.footer_banner {
	background-color: #B3B3B3;
	padding-top: 60px;
	padding-bottom: 60PX;
	margin-bottom: 0px;
	background-image: url(images/pattern.png);
	background-repeat: repeat;
}
footer {
	display: inline-block;
}
.hidden {
	display: none;
}
video {
	width: 800px;
	height: auto;
}
.product_text {
	text-align: justify;
	font-weight: lighter;
	line-height: 25px;
	padding-left: 10px;
	padding-right: 10px;
	color: #A3A3A3;
}

.product_text a{
	text-align: justify;
	font-weight: lighter;
	line-height: 25px;
	padding-left: 10px;
	padding-right: 10px;
	color: #616161;
}

section .footer_column div {
	text-align: center;
	color: #A3A3A3;
	font-weight: lighter;
	line-height: 25px;
}
#_downloadform form div {
	margin: 0 auto;
	width: 400px;
	display: inherit;
	padding-bottom: 10px;
}
#_downloadFormBtn    {
	width: 150px;
	padding-top: 10px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	margin-top: 0;
	margin-right: auto;
	margin-left: 170px;
	margin-bottom: 0;
	height: 40px;
	border-radius: 10px;
	font-size: 16px;
	font-family: arial;
	font-stretch: extra-expanded;
	background-image: -webkit-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: -moz-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: -o-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: linear-gradient(0deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-color: rgba(19,77,117,1.00);
	border-style: none;
	color: #FFFFFF;
}
#_downloadFormBtn:hover{
	font-family: arial;
	background-image: -webkit-linear-gradient(90deg,rgba(7,115,190,1.00) 5%,rgba(148,194,227,1.00) 100%);
	background-image: -moz-linear-gradient(90deg,rgba(7,115,190,1.00) 5%,rgba(148,194,227,1.00) 100%);
	background-image: -o-linear-gradient(90deg,rgba(7,115,190,1.00) 5%,rgba(148,194,227,1.00) 100%);
	background-image: linear-gradient(0deg,rgba(7,115,190,1.00) 5%,rgba(148,194,227,1.00) 100%);
	background-color: rgba(0,108,182,1.00);
	border-style: none;
	color: #FFFFFF;
}
#_downloadFormBtn:disabled {
    background: #dddddd;
}
#_downloadform form div div span label {
	width: 170px;
	display: inline-block;
}
#_downloadform form div div span input {
	width: 200px;
	padding-top: 10px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 5px;
	border: none #A3A3A3;
	border-bottom: solid 2px #c9c9c9;
	transition: border 0.3s;
	color: #A3A3A3;
	font-size: 14px;
	font-style: italic;
}
#_downloadform form div div span input:focus {
	border-bottom: solid 2px #969696;
	outline-color: transparent;
  	outline-style: none;
}
#_downloadform form div div span input:invalid {
	border-bottom: solid 2px red;
}

#_downloadform h2 {
	color: #6E6E6E;
}

.global_download_links_notation_for_non_desktop{
	color: black;
	text-align: justify;
	font-weight: normal;
	padding-left: 10px;
	padding-right: 10px;
}

/* Mobile */
@media (max-width: 320px) {
.logo {
	width: 100%;
	text-align: center;
	margin-top: 13px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
}
.container header nav {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	width: 100%;
	float: none;
	display: none;
}
header nav ul {
}
nav ul li {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	width: 100%;
	text-align: center;
}
.text_column {
	width: 100%;
	text-align: justify;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}
.thumbnail {
	width: 100%;
}
.footer_column {
	width: 100%;
	margin-top: 0px;
}
.parallax {
	text-align: center;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-top: 40%;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	width: 100%;
	font-size: 18px;
}
.parallax_description {
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	width: 90%;
	margin-top: 25px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 12px;
	float: none;
	text-align: center;
}
.banner {
	background-color: #2D9AB7;
	background-image: none;
}
.tagline {
	margin-top: 20px;
	line-height: 22px;
}
.hero_header {
	padding-left: 10px;
	padding-right: 10px;
	line-height: 22px;
	text-align: center;
}
}

/* Small Tablets */
@media (min-width: 321px)and (max-width: 767px) {
.logo {
	width: 100%;
	text-align: center;
	margin-top: 13px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	color: #043745;
}
.container header nav {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	width: 100%;
	float: none;
	overflow: auto;
	display: inline-block;
	background: #52bad5;
}
header nav ul {
	padding: 0px;
	float: none;
}
nav ul li {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	width: 100%;
	text-align: center;
	padding-top: 8px;
	padding-bottom: 8px;
}
.text_column {
	width: 100%;
	text-align: left;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}
.thumbnail {
	width: 100%;
}
.footer_column {
	width: 100%;
	margin-top: 0px;
}
.parallax {
	text-align: center;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-top: 40%;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	width: 100%;
	font-size: 18px;
}
.parallax_description {
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	margin-top: 30%;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	float: none;
	width: 100%;
	text-align: center;
}
.thumbnail {
	width: 50%;
}
.parallax {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-top: 20%;
}
.parallax_description {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	width: 100%;
	padding-top: 30px;
}
.banner {
	padding-left: 20px;
	padding-right: 20px;
}
.footer_column {
	width: 100%;
}
}

/* Desktops */
@media (min-width: 768px){
.text_column {
	width: 100%;
}
.thumbnail {
	width: 50%;
}
.text_column {
	width: 100%;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}
.banner {
	margin-top: 0px;
	padding-top: 0px;
}
}
.downloadDiv {
	width: 200px;
	height: 150px;
	float: right;
	margin-left: 10px;
	align-content: center;
	display: block;
	margin-right: 100px;
	padding-top: 70px;
	padding-bottom: 50px;
}
.downloadBtn {
	-webkit-box-shadow: inset 0px 0px 0px 0px rgba(19,77,117,1.00);
	box-shadow: inset 0px 0px 0px 0px rgba(19,77,117,1.00);
	background-image: -webkit-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: -moz-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: -o-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: linear-gradient(0deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-color: rgba(19,77,117,1.00);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border: 1px solid rgb(18, 77, 119);
	display: inline-block;
	cursor: pointer;
	color: rgb(255, 255, 255);
	font-family: Arial;
	font-size: 20px;
	padding: 32px 75px;
	text-decoration: none;
	text-align: center;
	text-shadow: 0px 1px 0px rgba(19,77,117,1.00);
	background-position: initial initial;
	background-repeat: initial initial;
}
.downloadConditions {
	margin-right: 0;
	margin-left: 35px;
	margin-bottom: 0;
	padding-top: 0px;
	padding-right: 0;
	padding-left: 40px;
	padding-bottom: 0;
	width: 100%;
}
#_download_message{
	font-size: small;
	color: green;
	align-content: center;
	text-align: center;
	height: 10px;
}
