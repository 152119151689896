.pluto_product_section{
	height: auto;
	display: inline-block;
}
.pluto_video_download_article_desktop{
    width: 100%;
    height: 400px!important;
    display: inline-block;
    float: left;
}

.pluto_video_desktop {
    width: 50%;
    height: 100%;
    float: left;
}

.pluto_video_download_article_non_desktop{
	width: 100%;
	height: 250px;
    display: block;
}

.pluto_download_div {
	width: 200px;
	height: 150px;
	margin-left: 10px;
	align-content: center;
	display: block;
    margin-right: 100px;
    margin-top: 0px;
	padding-top: 10px;
    padding-bottom: 50px;
    float:right;
}

.pluto_download_btn {
	-webkit-box-shadow: inset 0px 0px 0px 0px rgba(19,77,117,1.00);
	box-shadow: inset 0px 0px 0px 0px rgba(19,77,117,1.00);
	background-image: -webkit-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: -moz-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: -o-linear-gradient(90deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-image: linear-gradient(0deg,rgba(19,77,117,1.00) 5%,rgba(0,97,167,1.00) 100%);
	background-color: rgba(19,77,117,1.00);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border: 1px solid rgb(18, 77, 119);
	display: inline-block;
	cursor: pointer;
	color: rgb(255, 255, 255);
	font-family: Arial;
	font-size: 20px;
	padding: 32px 75px;
	text-decoration: none;
	text-align: center;
	text-shadow: 0px 1px 0px rgba(19,77,117,1.00);
	background-position: initial initial;
    background-repeat: initial initial;
}

