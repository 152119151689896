.mass_mailer_product_section{
    width: auto;
}

.mass_mailer_download_options{
    text-align: center;
    width: 100%;
}

.mass_mailer_download_options div{
    display: inline-block;
    margin-left: 63px;
    width: 63px;
    height: 63px;
}
.mass_mailer_download_options div a{
    width: 100%;
    height: 100%;
}
.mass_mailer_download_windows{
    background: url('../../images/os_download_icons.png') 0 0;
}

.mass_mailer_download_mac{
    background: url('../../images/os_download_icons.png') -254px 0 ;
}

.mass_mailer_download_linux{
    background: url('../../images/os_download_icons.png') -507px 0;
}